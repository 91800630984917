import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';

export const getLang = () => {
  const lang = localStorage.getItem('lang');
  return lang ? JSON.parse(lang) : { value: 'es' };
};

@Component({
  selector: 'body[root]',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, RouterLinkActive, RouterModule],
  template: `<router-outlet />`
})
export class AppComponent {
  constructor(
    private readonly modal: NgbModal,
    private readonly router: Router,
    private readonly translate: TranslateService,

  ) {
    this.translate.setDefaultLang(getLang()?.value);

    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(() => this.modal.dismissAll());

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => (window as unknown as any)?.KTDrawer?.init());
  }
}
