import { IEnvironment } from '@shared/interfaces';
import packageJSON from '../../package.json';

export const environment: IEnvironment = {
  production: false,
  appName: 'Webflow',
  version: packageJSON.version,
  API_KEY: '',
  BASE_URL: 'https://api.dev.webflow.biometriaavanzada.com.do',
  API_CLOUDFRONT: '',
};
